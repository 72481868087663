<template>
  <div>
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Checklist</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group label="Title" invalid-feedback="Title is required.">
              <b-form-input
                ref="title"
                v-model="checkObj.title"
                placeholder="Enter title"
                @focusout="checkTitle()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Save()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="allChecklist"
          :fields="fields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editCL(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteCL(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
      @hidden="hideOther()"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ sidebarTitle }}</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group label="Name" invalid-feedback="Name is required.">
              <b-form-input
                ref="other_name"
                v-model.trim="otherObj.name"
                placeholder="Enter name"
                @focusout="checkOtherName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveOther()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="otherData"
          :fields="otherFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editOther(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteOther(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-col md="12" class="d-flex flex-wrap justify-content-between mb-1">
      <div class="d-flex flex-wrap">
        <h3 class="mb-0">Profile Information</h3>
        <div class="pagination ml-1 align-items-center" v-if="currentInq > 0">
          <span
            class="page-item prev-item"
            :class="{ disabled: currentPage == 1 }"
          >
            <button
              class="page-link bg-white"
              style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1)"
              @click="move('prev')"
            >
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </button>
          </span>
          <span>
            <input
              v-model.trim="pageInput"
              class="form-control d-inline"
              style="width: 40px; height: auto; padding: 0.28rem 0.438rem"
              @keyup.enter="validateNum()"
            />
            <!-- {{ currentPage }} -->
            /
            {{ this.$store.state.inquiryData.length }}
          </span>
          <span
            class="page-item next-item"
            :class="{
              disabled: currentPage == this.$store.state.inquiryData.length,
            }"
          >
            <button
              class="page-link bg-white"
              style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1)"
              @click="move('next')"
            >
              <feather-icon icon="ChevronRightIcon" size="18" />
            </button>
          </span>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between"
        v-if="currentInq > 0"
      >
        <h4 class="bv-no-focus-ring col-form-label mr-1">Next on save:</h4>
        <b-form-checkbox v-model="nextOnsave" switch />
      </div>
    </b-col>
    <b-card class="">
      <b-row class="">
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Lead Title"
            invalid-feedback="Lead Title is required."
          >
            <b-form-input
              id="mc-first-name"
              placeholder="Enter title"
              ref="name"
              v-model.trim="myObj.name"
              @focusout="CheckName()"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Person on contact"
            invalid-feedback="POC is required."
          >
            <b-form-input
              id="mc-first-name"
              placeholder="Enter person on contact"
              ref="fname"
              v-model.trim="myObj.father_name"
            />
            <!-- @focusout="CheckFName()" -->
          </b-form-group>
        </b-col>

        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Contact number"
            invalid-feedback="Please enter correct phone number."
          >
            <vue-tel-input
              v-model="myObj.contact"
              :dropdownOptions="dropOptions"
              @input="CheckMobile"
            ></vue-tel-input>
            <small class="mr-1 text-danger" v-if="contacterror"
              >Contact number is required.</small
            >
          </b-form-group>
        </b-col>

        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Email"
            invalid-feedback="Email address is required."
          >
            <b-form-input
              ref="email"
              type="email"
              id="mc-email"
              placeholder="myskool@example.com"
              v-model="myObj.email"
            />
            <!-- @focusout="CheckEmail()" -->
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              ref="date"
              v-model="myObj.date"
              :config="config"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Location"
            invalid-feedback="Location Area is required."
            ref="loc"
          >
            <b-form-input
              placeholder="Enter location"
              v-model="myObj.residentialArea"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Quotation"
            invalid-feedback="Quotation is required."
          >
            <b-form-input
              ref="quotation"
              placeholder="Enter Quotation"
              v-model="myObj.quotation"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group invalid-feedback="Tag is required.">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Tag
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryTag')"
              />
            </label>
            <v-select
              v-model="myObj.tagID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tagData"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="tag"
              placeholder="Select tag"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group invalid-feedback="Status is required.">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Status
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryStatus')"
              />
            </label>
            <v-select
              v-model="myObj.statusID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusData"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="status"
              placeholder="Select status"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group invalid-feedback="Activity is required.">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Activity
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryActivity')"
              />
            </label>
            <v-select
              v-model="myObj.activityID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="activityData"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="act"
              placeholder="Select activity"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group invalid-feedback="Reference is required.">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Reference
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryReferrence')"
              />
            </label>
            <v-select
              v-model="myObj.referenceID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="referenceData"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="refer"
              placeholder="Select reference"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group invalid-feedback="Priority is required.">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Priority
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryPriority')"
              />
            </label>
            <v-select
              v-model="myObj.priorityID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="priorityData"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="prior"
              placeholder="Select priority"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group invalid-feedback="Category is required.">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Category
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryCategory')"
              />
            </label>
            <v-select
              v-model="myObj.categoryID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryData"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="cat"
              placeholder="Select category"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="12" cols="12">
          <b-form-group
            label="Next follow up date"
            invalid-feedback="Next follow up date is required."
            ref="next_date"
          >
            <flat-pickr
              ref="next_date"
              v-model="myObj.nextFollowup"
              :config="config"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="12" sm="12" cols="12">
          <b-form-group
            label="Latest comment"
            invalid-feedback="Latest comment is required."
          >
            <b-form-input
              ref="comment"
              placeholder="Enter latest comment"
              v-model="myObj.note"
            />
          </b-form-group>
        </b-col>
        <b-col xl="12" cols="12">
          <b-form-group
            :state="nameState"
            label="Upload Documents"
            invalid-feedback="Documents are required"
            required
            ref="docs"
            class=""
          >
            <div class="d-flex flex-wrap">
              <!-- <b-button
              ref="docs"
              @click="$refs.psamplefile.click()"
              variant="primary"
              :disabled="request || mydocloading"
              block
            >
              <b-spinner
                v-if="mydocloading"
                type="grow"
                small
                label="Loading..."
              />
              <span v-else>Upload</span>
            </b-button> -->
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
                style="width: 110px; height: 96px"
                @click="$refs.psamplefile.click()"
              >
                <b-spinner
                  v-if="mydocloading"
                  small
                  label="Loading..."
                  variant="primary"
                />
                <feather-icon
                  v-else
                  icon="PlusIcon"
                  size="34"
                  class="text-primary"
                />
              </div>
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
                style="width: 110px; height: 96px; position: relative"
                v-for="(file, index) in attachList"
                :key="index"
                @click="openFile(file)"
              >
                <b-avatar
                  size="20"
                  variant="primary"
                  style="position: absolute; top: -5px; right: -5px"
                >
                  <feather-icon
                    icon="XIcon"
                    @click.stop="deleteDocument(index)"
                  />
                </b-avatar>
                <feather-icon
                  :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
                  size="30"
                  class="text-primary"
                />
              </div>

              <input
                type="file"
                id="psamplefile"
                hidden
                ref="psamplefile"
                multiple
                @change="PsampleAdd()"
              />
            </div>
          </b-form-group>
        </b-col>
        <!-- <b-col>
          <b-badge
            style="margin-bottom: 5px"
            class="p-1"
            v-for="(file, index) in attachList"
            :key="index"
            variant="light-primary"
          >
            <a :href="file" target="_blank" class="text-primary">
              {{ index + 1 + " : " + file.slice(0, 40) }}
            </a>

            <i
              class="text-danger fa fa-trash"
              style="cursor: pointer"
              @click="deleteDocument(index)"
            ></i>
          </b-badge>
          
        </b-col> -->
      </b-row>
      <!-- 
      <b-row align-h="end">
        
       
      </b-row> -->
    </b-card>

    <b-card>
      <b-row class="p-50">
        <b-col xl="11" lg="10" md="9">
          <b-row>
            <b-col
              xl="2"
              lg="3"
              md="4"
              class="p-0 mb-50"
              v-for="li in formattedCL"
              :key="li"
            >
              <h5>{{ li.title }}</h5>
              <div class="d-flex align-items-center pr-50" style="width: 100%">
                <b-button
                  :variant="li.checkListData ? 'primary' : 'outline-primary'"
                  class="btn-icon rounded-circle mr-50"
                  @click="li.checkListData = !li.checkListData"
                >
                  <feather-icon icon="CheckIcon" size="16" />
                </b-button>

                <b-progress
                  style="width: 100%"
                  class="progress-bar-dark"
                  height="20px"
                  :value="li.checkListData ? '100' : '0'"
                  variant="success"
                ></b-progress>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="1" lg="2" md="3" class="mt-50 p-0">
          <!-- <b-button @click="AddOpen()" variant="primary" class="btn-icon" block>
            <feather-icon icon="PlusIcon" class="" />
            <span style="font-size: 80%">Checklist</span>
          </b-button> -->
          <b-badge
            @click="AddOpen()"
            variant="primary"
            class="cursor-pointer"
            style="float: right"
          >
            <feather-icon icon="PlusIcon" class="" />
            Checklist
          </b-badge>
        </b-col>
      </b-row>
    </b-card>
    <b-col md="12" class="d-flex align-items-center justify-content-between">
      <h3 class="mt-1">Follow up</h3>
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="bv-no-focus-ring col-form-label mr-1">Notifications:</h4>
        <b-form-checkbox v-model="notification" switch @input="setNoti()" />
      </div>
    </b-col>
    <b-card class="mt-1">
      <!-- <b-row>   
        <b-col
          xl="4"
          lg="4"
          md="6"
          sm="8"
          class="d-flex justify-content-between"
        >        
          <b-form-group
            label="Next follow up date"
            invalid-feedback="Next follow up date is required."
            ref="next_date"
          >
            <flat-pickr
              ref="next_date"
              v-model="myObj.nextFollowup"
              :config="config"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>    
      </b-row>
      <hr /> -->
      <b-row
        v-for="(item, ind) in followObj"
        :key="ind"
        style="display: flex; align-items: center"
      >
        <b-col md="2" class="">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              ref="date"
              :config="config"
              v-model="item.date"
              class="form-control"
              placeholder="Select Date."
            />
            <!-- @on-change="CheckDate()" -->
          </b-form-group>
        </b-col>
        <b-col md="7" class="">
          <b-form-group
            label="Note"
            invalid-feedback="Note is required."
            ref="response"
          >
            <b-form-input
              id="mc-first-name"
              ref="response"
              placeholder="Enter note here"
              v-model="item.note"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" class="">
          <b-form-group invalid-feedback="User is required." ref="user">
            <!-- <b-form-input
              ref="user"
              placeholder="Select user"
              v-model="item.followupBy"
            /> -->
            <label class="bv-no-focus-ring col-form-label pt-0"
              >By
              <feather-icon
                v-if="ind == 0"
                class="cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openOther('InquiryUser')"
              />
            </label>
            <v-select
              v-model="item.userID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allUsers"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="user"
              placeholder="Select user"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="2" class="">
          <b-form-group
            label="Next Date"
            invalid-feedback="Next Date is required."
            ref="next_date"
          >
            <flat-pickr
              ref="next_date"
              :config="config"
              v-model="item.nextDate"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col> -->
        <b-col md="1">
          <b-button
            v-if="ind == followObj.length - 1"
            @click="AddDesc(item)"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="18" icon="PlusIcon" />
          </b-button>
          <b-button
            v-if="followObj.length > 1"
            @click="removeDesc(item, ind)"
            variant="outline-danger"
            class="btn-icon rounded-circle ml-50"
          >
            <feather-icon size="18" icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row align-h="center">
      <b-col md="6">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          @click="Add()"
          :disabled="request || saveDisable"
          block
        >
          <b-spinner v-if="request" small type="grow" />
          <span v-else> Save </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

import * as XLSX from "xlsx";

export default {
  components: {
    //Multiselect,
    TheMask,
    BProgress,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    //BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
    BForm,
    BContainer,
    //BCardTitle,
    //BCardText,
    //BMediaAside,
    //BImg ,
    // BMedia,
    BAvatar,
    BLink,
    // BSpinner,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  async created() {
    // console.log(this.$route.params.id);

    if (this.$route.params.id == 0) {
      this.myObj.inquiryType = "inquiry";
      this.currentInq = 0;
    } else if (this.$route.params.id == -1) {
      this.myObj.inquiryType = "visitor";
      this.currentInq = 0;
    } else {
      let data = this.$route.params.id;
      this.currentInq = data.inquiry.id;
      this.currentPage =
        this.$store.state.inquiryData.findIndex(
          (el) => el.inquiry.id == this.currentInq
        ) + 1;
      this.pageInput = this.currentPage;
      // console.log(this.currentInq);
      this.myObj = data.inquiry;
      if (this.myObj.attachments !== null && this.myObj.attachments !== "") {
        this.attachList = this.myObj.attachments.split(",");
        // console.log(this.attachList);
      } else this.attachList = [];
      if (data.followups.length > 0) {
        this.followObj = data.followups;
      } else {
        this.followObj = [
          {
            id: 0,
            inquiryID: this.currentInq,
            campusID: this.$store.state.userData.cId,
            date: new Date().toJSON(),
            followupBy: "",
            note: "",
            userID: 0,
          },
        ];
      }
      // if (data.checklist) {
      //   this.setChecklist(1)
      // }
      // this.Edit(this.$route.params.id);
    }
    // console.log(this.myObj, this.followObj);

    this.loadChecklist(0);
    this.loadTags();
    this.loadActivity();
    this.loadCategory();
    this.loadPriority();
    this.loadRefs();
    this.loadStatus();
    this.loadUsers();
  },
  data() {
    return {
      currentPage: 1,
      currentInq: null,

      followObj: [
        {
          id: 0,
          inquiryID: this.currentInq,
          campusID: this.$store.state.userData.cId,
          date: new Date().toJSON(),
          followupBy: "",
          note: "",
          userID: 0,
        },
      ],
      formattedCL: [
        // {
        //   id: 0,
        //   inquiryID: this.currentInq,
        //   campusID: this.$store.state.userData.cId,
        //   checklistID: 0,
        //   checkListData: false,
        // },
      ],
      visibility2: false,
      currentID: null,
      rights: {},
      filter: {
        clsIDs: [],
        tags: [],
        campusID: this.$store.state.userData.cId,
        db: this.$store.state.userData.db,
      },

      contacterror: false,
      mydocloading: false,
      saveDisable: false,
      docsloading: "Upload",
      request: false,

      sidebarTitle: "",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      visibility: false,

      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      fields: [
        { label: "title", key: "title" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      myObj: {
        id: 0,
        name: "",
        father_name: "",
        contact: "",
        email: "",
        address: "",
        purpose: "",
        tag: "",
        date: new Date().toJSON(),
        attachments: "",
        campusID: this.$store.state.userData.cId,
        cls: 0,
        cnic: "",
        nextFollowup: new Date().toJSON(),
        inquiryType: "",
        note: "",
        quotation: "",
        priorityID: 0,
        statusID: 0,
        activityID: 0,
        referenceID: 0,
        categoryID: 0,
        tagID: 0,
      },
      priorityData: [],
      statusData: [],
      activityData: [],
      referenceData: [],
      categoryData: [],
      tagData: [],
      allUsers: [],
      controller: "",
      otherObj: {},
      otherFields: [
        { label: "name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      otherData: [],
      nextOnsave: true,
      notification: this.$store.state.inqNotification,
      attachList: [],
      checkObj: {
        id: 0,
        title: "",
        details: "",
        campusID: this.$store.state.userData.cId,
      },
      accIcon: "",
      allChecklist: [],
      saving: false,
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      searchDate: "",
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },

      uploadFields: {
        Name: "name",
        Father: "father_name",
        Contact: "contact",
        Email: "email",
        Purpose: "purpose",
        Tag: "tag",
        Date: "date",
      },
      exporting: false,
      clicked: 1,
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    handleEvent(event) {
      if (event.ctrlKey && event.keyCode === 83) {
        // console.log(event);
        event.preventDefault();
        if (this.clicked == 1) {
          this.clicked = 0;
          this.Add();
        }
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg|mp4)$/.test(url);
    },
    openFile(url) {
      window.open(url, "_blank");
    },

    validateNum() {
      var re = /^[0-9]+$/;
      let x = parseInt(this.pageInput);
      if (
        !re.test(this.pageInput) ||
        isNaN(x) ||
        x <= 0 ||
        x > this.$store.state.inquiryData.length
      ) {
        this.$bvToast.toast("Please enter the valid number", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.currentPage = this.pageInput;
        this.setData();
      }
    },
    move(text) {
      if (text == "prev") {
        this.currentPage -= 1;
      } else this.currentPage += 1;

      this.pageInput = this.currentPage;
      this.setData();
    },
    setData() {
      let data = this.$store.state.inquiryData[this.currentPage - 1];
      this.currentInq = data.inquiry.id;

      this.myObj = data.inquiry;
      if (this.myObj.attachments !== null && this.myObj.attachments !== "") {
        this.attachList = this.myObj.attachments.split(",");
        // console.log(this.attachList);
      } else this.attachList = [];

      if (data.followups.length > 0) {
        this.followObj = data.followups;
      } else {
        this.followObj = [
          {
            id: 0,
            inquiryID: this.currentInq,
            campusID: this.$store.state.userData.cId,
            date: new Date().toJSON(),
            followupBy: "",
            note: "",
            userID: 0,
          },
        ];
      }

      if (data.checklist.length == 0) {
        this.formattedCL = this.allChecklist.map((item) => ({
          id: 0,
          inquiryID: this.currentInq,
          campusID: this.$store.state.userData.cId,
          checklistID: item.id,
          checkListData: false,
          title: item.title,
        }));
      } else {
        let checkData = [];
        this.allChecklist.forEach((el) => {
          let item = data.checklist.find((ch) => ch.checklistID == el.id);
          if (item) {
            item.title = item.meta_text;
            checkData.push(item);
          } else {
            checkData.push({
              id: 0,
              inquiryID: this.currentInq,
              campusID: this.$store.state.userData.cId,
              checklistID: el.id,
              checkListData: false,
              title: el.title,
            });
          }
        });
        this.formattedCL = [...checkData];
      }
    },

    async loadTags() {
      this.tagData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryTag?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadActivity() {
      this.activityData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryActivity?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadCategory() {
      this.categoryData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryCategory?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadPriority() {
      this.priorityData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryPriority?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadRefs() {
      this.referenceData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryReferrence?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadStatus() {
      this.statusData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryStatus?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadUsers() {
      this.allUsers = await this.get({
        url:
          this.$store.state.domain +
          "InquiryUser?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },

    openOther(text) {
      this.controller = text;

      if (this.controller == "InquiryTag") {
        this.sidebarTitle = "Tag";
        this.otherData = this.tagData;
      } else if (this.controller == "InquiryActivity") {
        this.sidebarTitle = "Activity";
        this.otherData = this.activityData;
      } else if (this.controller == "InquiryCategory") {
        this.sidebarTitle = "Category";
        this.otherData = this.categoryData;
      } else if (this.controller == "InquiryPriority") {
        this.sidebarTitle = "Priority";
        this.otherData = this.priorityData;
      } else if (this.controller == "InquiryReferrence") {
        this.sidebarTitle = "Referrence";
        this.otherData = this.referenceData;
      } else if (this.controller == "InquiryStatus") {
        this.sidebarTitle = "Status";
        this.otherData = this.statusData;
      } else if (this.controller == "InquiryUser") {
        this.sidebarTitle = "User";
        this.otherData = this.allUsers;
      }

      this.otherObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility2 = true;
      var elem = this.$refs["other_name"];
      elem.state = undefined;
      this.buttonIcon = "PlusIcon";
    },
    editOther(item) {
      this.otherObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["other_name"];
      elem.state = undefined;
    },
    checkOtherName() {
      var elem = this.$refs["other_name"];
      if (this.otherObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveOther() {
      if (this.checkOtherName() == true) {
        this.saving = true;

        if (this.otherObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              this.controller +
              "?db=" +
              this.$store.state.userData.db,
            body: this.otherObj,
            message: this.sidebarTitle + " added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              this.controller +
              "/" +
              this.otherObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: this.sidebarTitle + " updated successfully.",
            context: this,
            body: this.otherObj,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        }
        if (status) {
          this.loadOther();

          var elem = this.$refs["other_name"];
          elem.state = undefined;
          this.buttonIcon = "PlusIcon";
          this.otherObj = {
            id: 0,
            name: "",
            campusID: this.$store.state.userData.cId,
          };
        }
      }
    },
    async loadOther() {
      this.otherData = await this.get({
        url:
          this.$store.state.domain +
          this.controller +
          "?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async deleteOther(item) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            this.controller +
            "/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,

          message: this.sidebarTitle + " deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadOther();
      }
    },
    hideOther() {
      if (this.controller == "InquiryTag") {
        this.tagData = this.otherData;
      } else if (this.controller == "InquiryActivity") {
        this.activityData = this.otherData;
      } else if (this.controller == "InquiryCategory") {
        this.categoryData = this.otherData;
      } else if (this.controller == "InquiryPriority") {
        this.priorityData = this.otherData;
      } else if (this.controller == "InquiryReferrence") {
        this.referenceData = this.otherData;
      } else if (this.controller == "InquiryStatus") {
        this.statusData = this.otherData;
      } else if (this.controller == "InquiryUser") {
        this.allUsers = this.otherData;
      }
    },

    AddDesc(item) {
      if (item.note.trim() != "" && item.userID != 0) {
        this.followObj.push({
          id: 0,
          inquiryID: this.currentInq,
          campusID: this.$store.state.userData.cId,
          date: new Date().toJSON(),
          followupBy: "",
          note: "",
          userID: 0,
        });
        // console.log(this.followObj);
      }
    },
    async removeDesc(item, ind) {
      if (item.id == 0) {
        this.followObj.splice(ind, 1);
        // console.log(this.followObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "InquiryFollowups/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.followObj.splice(ind, 1);
        // console.log(this.followObj);
      }
    },
    CheckValues() {
      let state = true;
      this.followObj.forEach((el) => {
        if (el.date == "" || el.note.trim() == "" || el.userID == 0) {
          state = false;
        }
      });
      return state;
    },
    async Save() {
      // console.log("obj:", this.checkObj);
      if (this.checkTitle() == true) {
        this.saving = true;

        if (this.checkObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "InquiryChecklist?db=" +
              this.$store.state.userData.db,
            body: this.checkObj,
            message: "Checklist added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "InquiryChecklist/" +
              this.checkObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Checklist updated successfully.",
            context: this,
            body: this.checkObj,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        }
        if (status) {
          this.loadChecklist(1);
          var elem = this.$refs["title"];
          elem.state = undefined;
          this.checkObj = {
            id: 0,
            title: "",
            details: "",
            campusID: this.$store.state.userData.cId,
          };
          this.accIcon = "PlusIcon";
        }
      }
    },
    setNoti() {
      this.$store.commit("setInquiry", this.notification);
    },
    deleteDocument(index) {
      this.attachList.splice(index, 1);
      // console.log("list", this.attachList);
      this.myObj.attachments = this.attachList.join(",");
    },
    PsampleAdd() {
      // console.log(this.$refs.psamplefile.files);

      if (this.$refs.psamplefile.files.length !== 0) {
        this.mydocloading = true;
        this.saveDisable = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append("file", this.$refs.psamplefile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.myskool.app", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              // this.sampleloading = false;
              var fn = result.myresp[i].path;
              // console.log("fn", fn);
              this.attachList.push(fn);
            }
            // console.log("Hello", this.attachList);
            this.myObj.attachments = this.attachList.join(",");
            this.mydocloading = false;
            this.saveDisable = false;
          })

          .catch((error) => console.log(error));
      }
    },
    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "StudentInquiries/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      console.log("editObj:", this.myObj);
      if (this.myObj.attachments !== null && this.myObj.attachments !== "") {
        this.attachList = this.myObj.attachments.split(",");
        // console.log(this.attachList);
      } else this.attachList = [];

      // var elem = this.$refs["name"];
      // elem.state = undefined;
      // var elem = this.$refs["fname"];
      // elem.state = undefined;
      // this.contacterror = false;
      // var elem = this.$refs["email"];
      // elem.state = undefined;
      // var elem = this.$refs["purpose"];
      // elem.state = undefined;
      // var elem = this.$refs["tag"];
      // elem.state = undefined;
    },
    setChecklist() {
      if (
        this.$route.params.id == 0 ||
        this.$route.params.id == -1 ||
        !this.$route.params.id.checklist
      ) {
        // console.log("not");
        this.formattedCL = this.allChecklist.map((item) => ({
          id: 0,
          inquiryID: this.currentInq,
          campusID: this.$store.state.userData.cId,
          checklistID: item.id,
          checkListData: false,
          title: item.title,
        }));
      } else {
        // console.log("in");
        let data = [];
        this.allChecklist.forEach((el) => {
          let item = this.$route.params.id.checklist.find(
            (ch) => ch.checklistID == el.id
          );
          if (item) {
            item.title = item.meta_text;
            data.push(item);
          } else {
            data.push({
              id: 0,
              inquiryID: this.currentInq,
              campusID: this.$store.state.userData.cId,
              checklistID: el.id,
              checkListData: false,
              title: el.title,
            });
          }
        });
        this.formattedCL = data;
      }
      // console.log(this.formattedCL);
    },
    async loadChecklist(num) {
      this.allChecklist = await this.get({
        url:
          this.$store.state.domain +
          "InquiryChecklist?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
      // console.log(this.allChecklist);
      if (num == 0) {
        this.setChecklist();
      } else {
        let data = [];
        this.allChecklist.forEach((el) => {
          let item = this.formattedCL.find((ch) => ch.checklistID == el.id);
          if (item) {
            data.push({
              id: item.id,
              inquiryID: item.inquiryID,
              campusID: this.$store.state.userData.cId,
              checklistID: item.checklistID,
              checkListData: item.checkListData,
              title: el.title,
            });
          } else {
            data.push({
              id: 0,
              inquiryID: this.currentInq,
              campusID: this.$store.state.userData.cId,
              checklistID: el.id,
              checkListData: false,
              title: el.title,
            });
          }
        });

        this.formattedCL = data;
        // console.log(this.formattedCL);
      }
    },
    AddOpen() {
      this.accIcon = "PlusIcon";
      this.checkObj = {
        id: 0,
        title: "",
        details: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      var elem = this.$refs["title"];
      elem.state = undefined;
    },
    async editCL(item) {
      this.checkObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["title"];
      elem.state = undefined;
      // console.log(this.checkObj);
    },

    async deleteCL(item) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "InquiryChecklist/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Checklist deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadChecklist(1);
      }
    },

    CheckMobile(s, e) {
      if (this.myObj.contact != "") {
        if (e.valid) {
          this.contacterror = false;
          this.myObj.contact = e.number;
          // console.log(this.myObj.contact);
        } else {
          this.contacterror = true;
        }
      } else {
        this.contacterror = false;
      }
      // console.log(this.myObj.contact.length);
      // if (this.myObj.contact.length == 12) {
      //   return (this.contacterror = false);
      // } else {
      //   return (this.contacterror = true);
      // }
    },
    CheckEmail() {
      var elem = this.$refs["email"];
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        re.test(String(this.myObj.email).toLowerCase()) &&
        this.myObj.email !== ""
      ) {
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPurpose() {
      var elem = this.$refs["purpose"];
      if (this.myObj.purpose == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTag() {
      var elem = this.$refs["tag"];
      if (this.myObj.tag == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
    },
    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkTitle() {
      var elem = this.$refs["title"];
      if (this.checkObj.title === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async Add() {
      this.CheckName();
      // this.CheckFName();
      // this.CheckEmail();
      // this.CheckPurpose();
      // this.CheckTag();
      if (!this.myObj.contact) this.contacterror = true;
      if (
        this.CheckName() == false ||
        this.contacterror
        // this.CheckValues() == false
        // this.CheckFName() == false ||
        // this.CheckEmail() == false ||
        // this.CheckPurpose() == false ||
        // this.CheckTag() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.clicked = 1;
      } else {
        this.request = true;
        this.myObj.notification = this.notification;

        if (this.myObj.nextFollowup == "") {
          this.myObj.nextFollowup = null;
        }

        let data = {
          inquiry: this.myObj,
          checklist: this.formattedCL,
          followups: this.followObj,
        };
        var status = await this.post({
          url:
            this.$store.state.domain +
            "studentinquiries/Save?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&sms=" +
            this.notification,
          body: data,
          message: "Inquiry saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          // toggle on
          if (this.nextOnsave && this.currentInq > 0) {
            var elem = this.$refs["name"];
            elem.state = undefined;
            if (this.currentPage != this.$store.state.inquiryData.length) {
              this.move("next");
            }
            this.clicked = 1;
          } else this.$router.go(-1);
        } else this.clicked = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.border-dashed {
  border: 1px solid var(--primary);
  border-style: dashed;
}
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
